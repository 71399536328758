import React, { Component } from "react";
import Layout from "../components/layout"
import { graphql } from "gatsby";
import SEO from "../components/seo"
import Helmet from "react-helmet"

import { Link } from "gatsby"
class SubscribePage extends Component {

  render() {
    return (
      <Layout>
      <Helmet>
        <script> var _ctct_m = "ba2156e83baf94169bb287e0829bce8d"; </script>
        <script id="signupScript" src="//static.ctctcdn.com/js/signup-form-widget/current/signup-form-widget.min.js" async defer></script>
      </Helmet>
        <p className="back-home"><a href="/">&#10005;</a></p>

        <SEO title="futurefeed | subscribe" />
        <div className="ctct-inline-form" data-form-id="d982bdf9-780c-4285-bcdb-70bdded62b62"></div>
      </Layout>
    )
  }
}

export default SubscribePage
